<template>
   <div class="modal__content__box">
      <div :key="componentKey" class="modal__content">
         <div class="item__modal">
            <Typography weigth="weigth" :size="'bigger'" v-if="selected.size > 0"
               >Add {{ selected.size }} {{ selected.size > 1 ? 'tips' : 'tip' }} to the list</Typography
            >
            <Typography weigth="weigth" :size="'bigger'" v-else>No tips selected</Typography>
         </div>
         <form action="" @submit.prevent="addToLists">
            <div class="checkBox_box">
               <SWCheckbox
                  class="chackBox_addModal"
                  variant="secondary"
                  size="big"
                  v-for="list in lists"
                  :key="list._id"
                  @input="value => selectList(list._id, value)"
                  v-show="list.name != 'All'"
               >
                  <span  class="checkBox-text"> {{ list.name }}</span>
               </SWCheckbox>
            </div>
            <div class="content_CreateList">
               <button type="button" @click="createList">
                  <div class="content_btnCreate">
                     <div class="img_create">
                        <img src="../../assets/img/KrestickAddCupon.svg" alt="" />
                     </div>
                     <div class="create_text">Create a new list</div>
                  </div>
               </button>
            </div>
            <div class="button_check__modal">
               <SWButton variant="primary" type="submit" size="big">Save </SWButton>
            </div>
            <div class="button_skip__modal">
               <SWButton :isCurrentClass="true" variant="secondary" size="big" @click="$emit('onCancel')"
                  >Cancel</SWButton
               >
            </div>
         </form>
      </div>
   </div>
</template>

<script>
import { SWButton, SWCheckbox } from 'sw-ui-kit';
import Typography from '../../components/UI/Typography.vue';
import { FORM_CREATE_LIST } from '../../components/Layouts/LayoutAplication.vue';
export default {
   inject: ['openModal', 'snackBar'],
   components: {
      Typography,
      SWButton,
      SWCheckbox,
   },
   props: {
      selected: {
         type: Set,
         default: null,
      },
   },

   data() {
      return {
         // isOpen: false,
         valueInput: '',
         selectedLists: [],
         componentKey: 0,
      };
   },

   mounted() {
      if (sessionStorage.getItem('isShowImprovePrompt')) {
         try {
            this.isOpen = JSON.parse(sessionStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      }
   },

   methods: {
      createList() {
         this.openModal(FORM_CREATE_LIST, { records: this.recordsToSave });
         this.$emit('onCancel');
      },
      onSkip() {
         sessionStorage.setItem('isShowImprovePrompt', false);
         this.isOpen = false;
      },
      submitHandler() {
         const result = {
            valueInput: this.valueInput,
         };

         console.log(result);
         this.register(result);
      },

      async register(data) {
         try {
            await this.$http.put('resources/profile/coupon', { coupon: data });
         } catch (err) {
            console.error(err);
            this.err = err;
         }
      },

      async addToLists() {
         if (this.selectedLists.length > 0) {
            await this.$http.post(`/resources/list/addAdvice`, {
               lists: this.selectedLists,
               records: this.recordsToSave,
            });
            this.$emit('onSave');

            this.snackBar(
               `Added ${this.recordsToSave.length} ${this.recordsToSave.length > 1 ? 'tips' : 'tip'} to the list`,
            );

            /*             const requests = [];
            this.selectedLists.forEach(id => {
               const list = this.lists.find(list => list._id === id);

               requests.push(
                  this.$http.put('/resources/profile/advice/create', {
                     advice_ids: this.selected.filter(
                        selId => !this.allAdvicesInLists.some(el => el.advice_id === selId && el.list_id === id),
                     ),

                     list_id: id,
                  }),
               );

               this.snackBar(
                  `Add ${this.selected.length} ${this.selected.length > 1 ? 'tips' : 'tip'} to the ${list.name} list`,
               );
            });

            Promise.all(requests).then(() => {
               this.$store.dispatch(`advice/${actions.FETCH}`);
               this.$store.commit('advice/udpateSelected', []);
            });
            this.$store.commit('advice/updateIsEditMenuOpen', false);
            this.$emit('onCancel'); */
         }
      },

      selectList(id, isSelected) {
         if (isSelected) {
            this.selectedLists.push(id);
         }
         if (!isSelected) {
            this.selectedLists = this.selectedLists.filter(function (item) {
               return item !== id;
            });
         }
      },
   },

   computed: {
      lists() {
         return this.$store.getters['app/getCustomLists'];
      },
      recordsToSave() {
         return Array.from(this.selected);
      },
      categoryListHasChanges() {
         //!crutch for update category list when add or remove list item
         return this.$store.getters['app/getCategoryListHaschanges'];
      },

      /*       selected() {
         return this.$store.getters['advice/getSelected'];
      }, */
      /*    allAdvicesInLists() {
         return this.$store.getters['advice/getUserLists'];
      }, */
   },
   watch: {
      async categoryListHasChanges() {
         this.componentKey++;
      },
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;

   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: var(--font-color-black);
}
.modal__content {
   display: flex;
   overflow-y: scroll;
   max-height: 700px;
   flex-direction: column;
   width: 340px;
}
.content_btnCreate {
   /* padding-bottom: 25px; */
   transform: translateX(-57px);
   /* margin-bottom: 30px; */
}
.img_create {
   margin-right: 38.9px;
}
.input__prising_modal {
   margin-bottom: 37px;
}

.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}

.item__modal {
   margin: 0 auto;
   padding-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}
.checkBox_box {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-left: 40px;
   margin-top: 20px;
}
.content_CreateList {
   margin-left: 27px;
   transform: translateY(-20px);
}
.chackBox_addModal {
   padding-bottom: 65px;
   height: 24px;
   position: relative;
}
.checkBox-text {
   padding-right: 5px;
   /* padding-bottom: 10px; */
   position: absolute;
   top: 4px;
   left: 73px;
}
.content_btnCreate:hover {
   background: #e7f2ff;

   border-radius: 10px;
}
/* .create_text {
   padding: 12px;
}
.create_text:hover {
   background: #e7f2ff;

   border-radius: 10px;
} */
/* .check p {
   color: black;
} */
</style>
