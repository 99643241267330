<template>
   <div>
      <div class="player_container">
         <!-- <audio ref="audioPlayer" :src="audio"></audio> -->
         <Typography :size="'default'" class="slotPlayer">
            {{ activeAudio.text }}
         </Typography>
         <div class="player_content">
            <div class="controls">
               <button class="seekBackward" @click="seekBackward">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                     <path
                        d="M20.5 18.3773L20.5 3.62274C20.5 3.5134 20.4712 3.40601 20.4165 3.31134C20.3618 3.21667 20.2831 3.13806 20.1885 3.08341C20.0938 3.02876 19.9864 2.99999 19.877 3C19.7677 3.00001 19.6603 3.02879 19.5656 3.08346L6.5535 10.5764C6.47902 10.6193 6.41716 10.6811 6.37415 10.7556C6.33114 10.83 6.30849 10.9145 6.30849 11.0004C6.30849 11.0864 6.33114 11.1709 6.37415 11.2453C6.41716 11.3197 6.47902 11.3815 6.5535 11.4245L19.5656 18.9165C19.6604 18.9712 19.7678 19 19.8772 19C19.9867 19 20.0941 18.9711 20.1888 18.9164C20.2836 18.8616 20.3622 18.7829 20.4168 18.6881C20.4714 18.5933 20.5001 18.4867 20.5 18.3773Z"
                        fill="#105EBC"
                        stroke="#105EBC"
                        stroke-width="1.52381"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <rect x="4.5" y="21" width="4" height="21" rx="2" transform="rotate(-180 4.5 21)" fill="#105EBC" />
                  </svg>
               </button>
               <button @click="tornOnMusice(activeAudio.media, activeAudio._id)">
                  <span class="playBtn" v-if="!isPlaying"
                     ><svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                        <path
                           d="M1.5 1.81734V21.1827C1.50005 21.3262 1.53786 21.4671 1.60963 21.5914C1.68141 21.7156 1.78463 21.8188 1.90891 21.8905C2.03319 21.9623 2.17416 22 2.31766 22C2.46116 22 2.60212 21.9622 2.72639 21.8905L19.8048 12.056C19.9025 11.9996 19.9837 11.9185 20.0402 11.8208C20.0966 11.7231 20.1264 11.6123 20.1264 11.4994C20.1264 11.3866 20.0966 11.2757 20.0402 11.178C19.9837 11.0803 19.9025 10.9992 19.8048 10.9429L2.72639 1.10954C2.60204 1.03774 2.46096 0.99996 2.31737 1C2.17377 1.00004 2.03272 1.0379 1.9084 1.10977C1.78408 1.18163 1.68089 1.28497 1.60919 1.40939C1.5375 1.53381 1.49984 1.67491 1.5 1.81851V1.81734Z"
                           fill="#105EBC"
                           stroke="#105EBC"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        /></svg
                  ></span>
                  <span class="pauseBtn" @click.stop="audio.pause()" v-if="isPlaying"
                     ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                        <rect width="7" height="21" rx="2" fill="#105EBC" />
                        <rect x="10" width="7" height="21" rx="2" fill="#105EBC" /></svg
                  ></span>
               </button>
               <button class="seekForward" @click="seekForward">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                     <path
                        d="M1.5 2.62274V17.3773C1.50003 17.4866 1.52884 17.594 1.58353 17.6887C1.63822 17.7833 1.71686 17.8619 1.81155 17.9166C1.90624 17.9712 2.01365 18 2.12298 18C2.23231 18 2.33971 17.9712 2.43439 17.9165L15.4465 10.4236C15.521 10.3807 15.5828 10.3189 15.6259 10.2444C15.6689 10.17 15.6915 10.0855 15.6915 9.99956C15.6915 9.91358 15.6689 9.82913 15.6259 9.75469C15.5828 9.68025 15.521 9.61845 15.4465 9.57552L2.43439 2.08346C2.33965 2.02875 2.23216 1.99997 2.12276 2C2.01335 2.00003 1.90588 2.02887 1.81116 2.08363C1.71645 2.13839 1.63782 2.21712 1.5832 2.31192C1.52857 2.40671 1.49988 2.51333 1.5 2.62274Z"
                        fill="#105EBC"
                        stroke="#105EBC"
                        stroke-width="1.52381"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <rect x="17.5" width="4" height="21" rx="2" fill="#105EBC" />
                  </svg>
               </button>
               <button class="closePlayer" @click="clousePlayer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                     <rect x="0.5" width="20" height="20" rx="2" fill="#105EBC" />
                  </svg>
               </button>
            </div>
            <div class="volumeR">
               <input type="range" min="0" max="1" step="0.01" v-model="volume" @input="setVolume" />
               <button>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <g clip-path="url(#clip0_1062_6703)">
                        <path
                           d="M20 10.667C20.828 11.288 21.5 12.0932 21.9628 13.0189C22.4257 13.9446 22.6667 14.9654 22.6667 16.0003C22.6667 17.0353 22.4257 18.056 21.9628 18.9817C21.5 19.9075 20.828 20.7127 20 21.3337"
                           stroke="#105EBC"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M8 20.0003H5.33333C4.97971 20.0003 4.64057 19.8598 4.39052 19.6098C4.14048 19.3597 4 19.0206 4 18.667V13.3336C4 12.98 4.14048 12.6409 4.39052 12.3908C4.64057 12.1408 4.97971 12.0003 5.33333 12.0003H8L12.6667 6.00029C12.7832 5.77393 12.9769 5.59672 13.2127 5.50068C13.4484 5.40464 13.7108 5.3961 13.9523 5.47661C14.1939 5.55712 14.3986 5.72137 14.5296 5.93968C14.6607 6.15798 14.7093 6.41595 14.6667 6.66696V25.3336C14.7093 25.5846 14.6607 25.8426 14.5296 26.0609C14.3986 26.2792 14.1939 26.4435 13.9523 26.524C13.7108 26.6045 13.4484 26.5959 13.2127 26.4999C12.9769 26.4039 12.7832 26.2266 12.6667 26.0003L8 20.0003Z"
                           stroke="#105EBC"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                     </g>
                     <defs>
                        <clipPath id="clip0_1062_6703">
                           <rect width="32" height="32" fill="white" />
                        </clipPath>
                     </defs>
                  </svg>
               </button>
               <!-- <label for="file">File progress:</label> -->
            </div>
         </div>
         <div class="progress-bar audioFile">
            <div class="progress" :style="{ width: progress }"></div>
         </div>
      </div>
   </div>
</template>

<script>
import Typography from '../../components/UI/Typography.vue';
import remoteOrigin from '../../store/remoteOrigin';

export default {
   //    inject: ['onPlay'],
   props: {
      audioSrc: {
         type: String,
      },
      audiolist: {
         type: Array,
         require: true,
      },
      //   showAudioPlayer: {
      //      type: Boolean,
      //      default: false,
      //   },
   },

   components: {
      Typography,
   },
   data() {
      return {
         progress: null,
         isPlaying: false,
         audio: '',
         volume: 0.5,
         showPlayer: true,
         index: null,
         currentIndex: 0,
         delimeterAudio: new Audio('/audio/delimiter.mp3'),
      };
   },

   created() {},
   mounted() {
      //let indexVisible = this.listOfAdvices.findIndex(el => this.audiolist[el._id]);
      let indexVisible = 0;

      if (indexVisible !== -1) {
         this.currentIndex = indexVisible;
      }
      this.playAudio(this.activeAudio.media, this.activeAudio._id);
   },
   beforeDestroy() {
      this.clearEvents();
   },

   methods: {
      playAudio(media, id) {
         if (!media) return;
         this.index = id;
         if (this.audio) {
            this.clearEvents();
         }
         this.audio = new Audio(`${remoteOrigin}/storage/audio/${media}`);

         this.audio.play();

         this.audio.addEventListener('ended', this.audioEnded);

         this.audio.addEventListener('play', this.audioPlay);

         this.audio.addEventListener('timeupdate', this.updateProgress);

         this.audio.addEventListener('pause', this.audioPause);
      },

      clearEvents() {
         this.audio.muted = true;
         this.audio.removeEventListener('ended', this.audioEnded);

         this.audio.removeEventListener('play', this.audioPlay);

         this.audio.removeEventListener('timeupdate', this.updateProgress);

         this.audio.removeEventListener('pause', this.audioPause);
         this.audio = null;
      },

      audioEnded() {
         this.progress = 0;

         const playNextAfterDelimeter = () => {
            this.seekForward();
            this.delimeterAudio.removeEventListener('ended', playNextAfterDelimeter);
         };
         this.delimeterAudio.addEventListener('ended', playNextAfterDelimeter);
         this.delimeterAudio.play();
      },

      audioPlay() {
         this.isPlaying = true;
      },

      updateProgress() {
         this.progress = Math.ceil((this.audio.currentTime / this.audio.duration) * 100) + '%';
      },

      audioPause() {
         this.isPlaying = false;
      },
      setVolume() {
         this.audio.volume = this.volume;
      },
      muteVolume() {
         this.audio.volume = 0;
         this.volume = 0;
      },

      clousePlayer() {
         this.$emit('closePlayer');
      },

      tornOnMusice(media, id) {
         if (this.audio) {
            this.audio.play();
         } else {
            this.playAudio(media, id);
         }
      },

      seekBackward() {
         const isHasPrevAudio = this.currentIndex !== 0;

         this.currentIndex = isHasPrevAudio ? this.currentIndex - 1 : this.listOfAdvices.length - 1;
      },

      seekForward() {
         const isHasNextAudio = this.listOfAdvices.length !== this.currentIndex + 1;

         this.currentIndex = isHasNextAudio ? this.currentIndex + 1 : 0;
      },
   },
   computed: {
      // getVisibleItems() {
      //  return this.$store.getters['advice/getVisibleItems'];
      //},

      activeAudio() {
         return this.listOfAdvices[this.currentIndex];
      },

      listOfAdvices() {
         const res = [];
         this.audiolist.forEach(el => {
            if (!el.hasChilds) res.push(el);
            else if (el.childs) el.childs.forEach(el =>{ 
               res.push(el)
            });
         });
         return res;
      },
   },
   watch: {
      currentIndex() {
         this.playAudio(this.activeAudio.media, this.activeAudio._id);
      },
   },
};
</script>

<style scoped>
.progress-bar {
   width: 100%;
   background-color: #b9b9b9;
}

.progress {
   height: 4px;
   width: 0;
   border-radius: 10px;
   background-color: #105ebc;
   transition: width 0s ease-in-out;
}
.player_container {
   display: flex;
   position: relative;
   flex-direction: column;
   align-items: center;
   margin-top: 10px;
   background-color: var(--bg-player);
   max-width: 819px;
   border-radius: 10px;
   height: 143px;
}
.player_content {
   margin-top: 25px;
   margin-left: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.audioFile {
   max-width: 902px;
   position: absolute;
   bottom: 0;
   width: 100%;
}
.closePlayer {
   transform: translateY(-1px);
}
.controls {
   padding-top: 3px;
}
.seekBackward,
.seekForward {
   margin-right: 36px;
   margin-left: 36px;
   height: 21px;
}
.pauseBtn svg {
   width: 22px;
   height: 23px;
}
.volumeR {
   display: flex;
   align-items: center;
   transform: translateX(190px);
   /* position: absolute;
   right: 17px;
   top: 54%; */
}
.volumeR input {
   margin-right: 12px;
}
.volumeR button {
   margin-top: 3px;
}

input[type='range'] {
   max-width: 72px;
   width: 100%;
}
.slotPlayer {
   width: 100%;
   text-align: left;
   margin-top: 25.5px;
   font-weight: 500;
   margin-left: 16px;
   line-height: 1rem;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   max-height: 3.2rem;
   /* text-overflow: ellipsis;
   overflow: hidden;
   width: 100%;
   white-space: nowrap; */
}
input[type='range'] {
   -webkit-appearance: none;
   width: 100%;
   height: 4px;
   border-radius: 2px;
   background-color: #1073eb;
}

input[type='range']::-webkit-slider-thumb {
   -webkit-appearance: none;
   width: 16px;
   height: 16px;
   background-color: #1073eb;
   border-radius: 50%;
   cursor: pointer;
   -webkit-transition: background-color 0.15s ease-in-out;
   transition: background-color 0.15s ease-in-out;
}

input[type='range']::-moz-range-thumb {
   width: 16px;
   height: 16px;
   background-color: rgb(210, 210, 210);
   border-radius: 50%;
   cursor: pointer;
   transition: background-color 0.15s ease-in-out;
}

@media screen and (max-width: 600px) {
   .volumeR {
      display: none;
   }
   .slotPlayer {
      margin-top: 20px;
      margin-left: 35px;
      padding-right: 10px;
   }
   .player_content {
      margin-left: 0;
      transform: translateX(-79px);
   }
   .player_container {
      position: fixed;
      z-index: 100;
      bottom: 64px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
   }
}
</style>
