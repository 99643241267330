<template>
   <div class="container__habitsList">
      <Teleport to="#searchContainer">
         <SWSearchinput v-model="search" placeholder="Search for advice, habits" />
      </Teleport>
      <!-- BUTTON AND SERCH FOR ADVICES ROUTE =====================================================-->
      <Teleport to="#mobileSearchBox" v-if="'advice' === settigRouteName">
         <div class="box_imgMobailAdvice" id="mobileSearchBox">
            <div v-if="!mobileSearchActive">
               <div id="test"></div>
               <button
                  @click="mobileEditMenuActive = !mobileEditMenuActive"
                  :class="{ activeOpenMenu: mobileEditMenuActive }"
                  class="mobailAdvaciListImg"
                  v-show="currentCategory.name != 'All'"
               >
                  <!-- <img src="../../assets/img/listRedactImg.svg" alt="" /> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                     <g clip-path="url(#clip0_1383_19494)">
                        <path
                           d="M16.332 23.6667H19.1156L26.4223 16.36C26.7914 15.9909 26.9988 15.4903 26.9988 14.9682C26.9988 14.4462 26.7914 13.9456 26.4223 13.5765C26.0532 13.2074 25.5525 13 25.0305 13C24.5085 13 24.0079 13.2074 23.6388 13.5765L16.332 20.8832V23.6667Z"
                           stroke-width="1.5"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M22.9434 14.2734L25.7269 17.057"
                           stroke-width="1.4"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path d="M5.33398 8H26.6673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5 16H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.33398 24H10.6673" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                     </g>
                     <defs>
                        <clipPath id="clip0_1383_19494">
                           <rect width="32" height="32" fill="white" />
                        </clipPath>
                     </defs>
                  </svg>
               </button>
               <button class="lupaBtn" @click="mobileSearchActive = !mobileSearchActive">
                  <span @blur="showInput">
                     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <g clip-path="url(#clip0_1383_19502)">
                           <path
                              d="M11.6667 19.8333C16.177 19.8333 19.8333 16.177 19.8333 11.6667C19.8333 7.15634 16.177 3.5 11.6667 3.5C7.15634 3.5 3.5 7.15634 3.5 11.6667C3.5 16.177 7.15634 19.8333 11.6667 19.8333Z"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                           <path
                              d="M24.5 24.5L17.5 17.5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </g>
                        <defs>
                           <clipPath id="clip0_1383_19502">
                              <rect width="28" height="28" fill="white" />
                           </clipPath>
                        </defs>
                     </svg>
                  </span>
               </button>
            </div>

            <div>
               <SWSearchinput
                  @blur="onSerchBlur"
                  @clickOnCross="mobileSearchActive = false"
                  @focus="!mobileSearchActive"
                  v-model="search"
                  placeholder="Search for advice, habits"
                  class="showInputADviceMobail"
                  v-if="mobileSearchActive"
                  ref="search"
               />
            </div>
         </div>
      </Teleport>
      <!--END  BUTTON AND SERCH FOR ADVICES ROUTE =====================================================-->
      <!--BUTTON AND SERCH FOR HABITS ROUTE =====================================================-->
      <Teleport to="#mobileSearchBox" v-if="'habits' === settigRouteName">
         <div
            class="mobailAdviceVercion containerHabits box_imgMobailAdviceimgRedactBox"
            :class="{ active: 'habits' === settigRouteName }"
         >
            <div class="box_imgMobailAdviceimgRedact">
               <div>
                  <button
                     @click="mobileEditMenuActive = !mobileEditMenuActive"
                     :class="{ activeOpenMenu: mobileEditMenuActive }"
                     class="mobailAdvaciListImg imgRedact"
                  >
                     <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <g clip-path="url(#clip0_1383_19494)">
                           <path
                              d="M16.332 23.6667H19.1156L26.4223 16.36C26.7914 15.9909 26.9988 15.4903 26.9988 14.9682C26.9988 14.4462 26.7914 13.9456 26.4223 13.5765C26.0532 13.2074 25.5525 13 25.0305 13C24.5085 13 24.0079 13.2074 23.6388 13.5765L16.332 20.8832V23.6667Z"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                           <path
                              d="M22.9434 14.2734L25.7269 17.057"
                              stroke-width="1.4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                           <path
                              d="M5.33398 8H26.6673"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                           <path d="M5 16H14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                           <path
                              d="M5.33398 24H10.6673"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </g>
                        <defs>
                           <clipPath id="clip0_1383_19494">
                              <rect width="32" height="32" fill="white" />
                           </clipPath>
                        </defs>
                     </svg>
                  </button>
               </div>
               <SWSearchinput
                  @blur="onSerchBlur"
                  v-model="search"
                  placeholder="Search for habits"
                  class="showInputADviceMobail habitsInput"
               />
            </div>
         </div>
      </Teleport>
      <!--END  BUTTON AND SERCH FOR HABITS ROUTE =====================================================-->

      <!-- LIST CONTROLS =====================================================-->
      <div class="container_headerList">
         <div class="box_btn_playAll" v-show="!mobilePlayAllHide">
            <SWButton class="btn_play" variant="primary" fullWidth @click="playAll">
               <img class="img_playAll" src="../../assets/img/player.svg" alt="" />
               <span class="text_btnPlay"> Play all</span>
            </SWButton>
         </div>

         <div class="box_btn_editList">
            <SWButton
               @click="toggleEditcontrols"
               :isSetActiveClass="editControls"
               class="btn_edit"
               variant="secondary"
               fullWidth
               v-show="currentCategory.name != 'All'"
            >
               <img class="img_editList" src="../../assets/img/editList.svg" alt="" />
               <span class="text_btnEdit"> Edit list</span>
            </SWButton>
         </div>
         <div v-show="this.hidden.size > 0" class="save_cancel_container">
            <div class="box_btn_save">
               <SWButton class="btn_play btn_saveChanges" variant="primary" fullWidth @click="saveChanges">
                  <span class="text_btnPlay"> Save </span>
               </SWButton>
            </div>
            <div class="box_btn_cancel">
               <SWButton class="btn_edit btn_cancelChanges" variant="secondary" fullWidth @click="cancelChanges">
                  <span class="text_btnEdit"> Cancel </span>
               </SWButton>
            </div>
         </div>
      </div>
      <!-- END LIST CONTROLS =====================================================-->
      <Player v-if="showPlayer" :audiolist="this.list" @closePlayer="showPlayer = false" />
      <!-- EDIT CONTROLS ===================================================== -->
      <div>
         <Modal
            v-model="mobileEditMenuActive"
            :wrapperContentClasses="['modalStyleForEditeWindow']"
            :modalContentClasses="['modalStyleForEdite']"
         >
            <div id="editMenu" @click="isEditMenuOpen = false"></div>
         </Modal>
         <Teleport to="#editMenu" v-if="mobileEditMenuActive">
            <div>
               <div class="teleport__editMenu">
                  <button v-if="hidden.size > 0 && !showHiddenMode" @click="showHidden">
                     <Typography> Show hidden items</Typography>
                  </button>
                  <button v-if="showHiddenMode" @click="backToList">
                     <Typography> Show visible items</Typography>
                  </button>

                  <button
                     :disabled="isListCustom || isListPredefined"
                     v-show="!isListPredefined"
                     @click="restoreOriginalList"
                  >
                     <Typography>Restore the original list</Typography>
                  </button>
                  <button type="button" v-if="isListCustom && !isListPredefined" @click="renameList">
                     <Typography>Rename list</Typography>
                  </button>
                  <button type="button" v-if="isListCustom && !isListPredefined" @click="deleteList">
                     <Typography class="btnInTeleport_deleteList">Delete list</Typography>
                  </button>
                  <div v-show="this.hidden.size > 0" class="save_cancel_container_mobile">
                     <div class="box_btn_save">
                        <SWButton class="btn_play btn_saveChanges" variant="primary" fullWidth @click="saveChanges">
                           <span class="text_btnPlay"> Save </span>
                        </SWButton>
                     </div>
                     <div class="box_btn_cancel">
                        <SWButton
                           class="btn_edit btn_cancelChanges"
                           variant="secondary"
                           fullWidth
                           @click="cancelChanges"
                        >
                           <span class="text_btnEdit"> Cancel </span>
                        </SWButton>
                     </div>
                  </div>
               </div>
            </div>
         </Teleport>

         <div v-if="editControls && currentCategory.name != 'All'" class="btnMobilEditList">
            <!-- here is a problem-->
            <SWButton class="btnEditMobHiden" variant="secondary" @click="hideSelected">
               <span> Hide selected</span>
            </SWButton>
            <SWButton class="btnEditMobHiden" variant="secondary" v-if="showHiddenMode" @click="restoreHiddenSelected">
               <span> Show selected</span>
            </SWButton>

            <SWButton class="btnEditMobHiden" variant="secondary" @click="hideUnSelected">
               <span> Hide unselected</span>
            </SWButton>
            <SWButton
               class="btnEditMobHiden"
               variant="secondary"
               v-if="showHiddenMode"
               @click="restoreHiddenunselected"
            >
               <span> Show unselected</span>
            </SWButton>
            <button class="img_mobilClouse" @click="closeEditControlsMobile">
               <img src="../../assets/img/clouseediteMenu.svg" alt="" />
            </button>

            <SWButton size="big" class="btnEditMobHidenSave" variant="primary" @click="saveToList"
               >Save to list</SWButton
            >
         </div>

         <div v-if="editControls && !showHiddenMode" class="container__menuEditList">
            <div class="box_editItem_one">
               <div class="editList_item1">
                  <button
                     type="button"
                     class="btn_editItem1"
                     :disabled="!isListCustom || isListPredefined"
                     @click="renameList"
                  >
                     <img class="edit_img" src="../../assets/img/pen.svg" alt="" /><span>Rename list</span>
                  </button>
               </div>
               <div class="editList_item2">
                  <button class="btn_editItem2" :disabled="isListCustom" @click="restoreOriginalList">
                     <img class="edit_img" src="../../assets/img/download.svg" alt="" /><span>Restore the original list</span>
                  </button>
               </div>
               <div class="editList_item3">
                  <button
                     type="button"
                     class="btn_editItem3"
                     :disabled="!isListCustom || isListPredefined"
                     @click="deleteList"
                  >
                     <img class="edit_img" src="../../assets/img/basket.svg" alt="" /><span>Delete list</span>
                  </button>
               </div>
               <div :class="{ activeHiddenItem: hidden.size > 0 }" class="editList_item4">
                  <button type="button" class="btn_editItem4" @click="showHidden">
                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                           d="M8.0013 9.33268C8.73768 9.33268 9.33464 8.73573 9.33464 7.99935C9.33464 7.26297 8.73768 6.66602 8.0013 6.66602C7.26492 6.66602 6.66797 7.26297 6.66797 7.99935C6.66797 8.73573 7.26492 9.33268 8.0013 9.33268Z"
                           stroke-width="1.2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                        <path
                           d="M14.6654 8.00065C12.8874 11.112 10.6654 12.6673 7.9987 12.6673C5.33203 12.6673 3.11003 11.112 1.33203 8.00065C3.11003 4.88932 5.33203 3.33398 7.9987 3.33398C10.6654 3.33398 12.8874 4.88932 14.6654 8.00065Z"
                           stroke-width="1.2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                     </svg>
                     <span>Show hidden items</span>
                  </button>
               </div>
            </div>

            <div v-if="selected.size > 0" class="relative_box_editItem_two">
               <div class="box_editItem_two">
                  <div class="editList_item5">
                     <button v-if="!hidden.lenght" class="btn_editItem5" @click="hideSelected">
                        <img class="img_editList" src="../../assets/img/check.svg" alt="" /><span>Hide selected</span>
                     </button>
                     <!--      <button v-else-if="hidden.lenght > 0" class="btn_editItem5">
                        <img src="../../assets/img/check.svg" alt="" /><span>Show selected DESCk</span>
                     </button> -->
                  </div>
                  <div class="editList_item6">
                     <button v-if="!hidden.lenght" class="btn_editItem5" @click="hideUnSelected">
                        <img class="img_editList" src="../../assets/img/check.svg" alt="" /><span>Hide unselected</span>
                     </button>
                     <!--   <button v-else-if="hidden.lenght > 0" class="btn_editItem5">
                        <img src="../../assets/img/check.svg" alt="" /><span>Show unselected DESC</span>
                     </button> -->
                  </div>

                  <div class="editList_item7">
                     <button class="btn_editItem7" @click="saveToList">
                        <img class="img_editList" src="../../assets/img/saveToList.svg" alt="" /><span>Save to list</span>
                     </button>
                  </div>
               </div>

               <!--                <div class="container__menuHiddenItem">
                  <div class="box_HiddenItem_one">
                     <div class="backBtn_HiddenItem">
                        <button class="btn_HiddenItem">
                           <img src="../../assets/img/arrBackHidde.svg" alt="" /><span>Back to list </span>
                        </button>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
      </div>
      <div v-if="editControls && showHiddenMode" class="container__menuEditList">
         <div v-if="showHiddenMode" class="relative_box_editItem_two">
            <div class="box_editItem_two">
               <div class="editList_item6 is-desktop_show_selecter" v-if="selected.size > 0">
                  <button class="btn_editItem5" @click="restoreHiddenSelected">
                     <img src="../../assets/img/check.svg" alt="" /><span>Show selected</span>
                  </button>
                  <button class="btn_editItem5" @click="restoreHiddenunselected">
                     <img src="../../assets/img/check.svg" alt="" /><span>Show unselected</span>
                  </button>
               </div>
            </div>

            <div class="container__menuHiddenItem">
               <div class="box_HiddenItem_one">
                  <div class="backBtn_HiddenItem">
                     <button class="btn_HiddenItem" @click="backToList">
                        <img src="../../assets/img/arrBackHidde.svg" alt="" /><span>Back to list </span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- END EDIT CONTROLS ===================================================== -->

      <!-- LIST =====================================================-->
      <div class="box_list" :key="listKey">
         <ul class="content__habitsLList" v-if="list.length > 0">
            <li
               @touchstart="e => handleTouchStart(e, el._id)"
               @touchmove="handleTouchMove"
               v-for="el in list"
               :key="el._id"
            >
               <div
                  @click="selectItem(el._id)"
                  class="advice"
                  :class="{
                     backgroundPlayColor: showProgressBar && el._id === playingAdviceId,

                     hoverEffect: showProgressBar == 0,
                  }"
                  v-if="!el.hasChilds"
               >
                  <Typography
                     class="textHabis"
                     :size="'default'"
                     v-html="el.text ? highlightText(el.text) : el.title"
                  ></Typography>
                  <button
                     v-if="
                        (!editControls && !playPlayer && el._id !== playingAdviceId) ||
                        (!editControls && playPlayer && el._id !== playingAdviceId) ||
                        (!editControls && !playPlayer && el._id === playingAdviceId)
                     "
                     class="img_btn playerImg"
                     @click="handlePlay(el.media, el._id), closePlayerAll()"
                  >
                     <img src="../../assets/img/playerBlueBtn.svg" alt="" />
                  </button>

                  <button
                     @click="audio.pause(), (playPlayer = false)"
                     v-if="playPlayer && el._id === playingAdviceId"
                     class="img_btn playerImg"
                  >
                     <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                        <rect width="7" height="21" rx="2" fill="#105EBC" />
                        <rect x="10" width="7" height="21" rx="2" fill="#105EBC" />
                     </svg>
                  </button>

                  <SWCheckbox
                     v-if="editControls"
                     :checked="selected.has(el._id)"
                     @input="selectItem(el._id)"
                     @click.native.stop
                     class="img_btn checkboxImg"
                     variant="secondary"
                     size="big"
                  />
               </div>

               <div class="advices_cat" v-else-if="!allChildsHidden(el)">
                  <Typography class="textHabis" :size="'default'"> {{ el.text }}</Typography>
                  <ul class="content__adviceList">
                     <li
                        :class="{
                           marginBottom: showProgressBar && el._id === playingAdviceId,
                           backgroundPlayColor: showProgressBar && el._id === playingAdviceId,
                           hoverEffect: showProgressBar == 0,
                        }"
                        v-for="advice in el.childs"
                        :key="advice._id"
                     >
                        <div @click="selectItem(advice._id)" class="advice">
                           <Typography
                              class="textAdvices"
                              :size="'default'"
                              v-html="advice.text ? highlightText(advice.text) : advice.title"
                           ></Typography>

                           <!--<button class="img_btn playerImg" @click="onPlay(advice.media)"></button>-->
                           <button
                              v-if="
                                 (!editControls && !playPlayer && advice._id !== playingAdviceId) ||
                                 (!editControls && playPlayer && advice._id !== playingAdviceId) ||
                                 (!editControls && !playPlayer && advice._id === playingAdviceId)
                              "
                              @click="handlePlay(advice.media, advice._id)"
                              class="img_btn playerImg"
                           >
                              <img src="../../assets/img/playerBlueBtn.svg" alt="" />
                           </button>
                           <button
                              @click="audio.pause(), (playPlayer = false)"
                              v-if="playPlayer && advice._id === playingAdviceId"
                              class="img_btn playerImg"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="17"
                                 height="21"
                                 viewBox="0 0 17 21"
                                 fill="none"
                              >
                                 <rect width="7" height="21" rx="2" fill="#105EBC" />
                                 <rect x="10" width="7" height="21" rx="2" fill="#105EBC" />
                              </svg>
                           </button>

                           <SWCheckbox
                              v-if="editControls"
                              :checked="selected.has(advice._id)"
                              @input="selectItem(advice._id)"
                              class="img_btn checkboxImg"
                              @click.native.stop
                              variant="secondary"
                              size="big"
                           />
                        </div>
                        <div
                           v-if="
                              (showProgressBar && !advice.advice && advice._id === playingAdviceId) ||
                              (playPlayer && !advice.advice && advice._id === playingAdviceId)
                           "
                           class="progress-bar audioFile"
                        >
                           <div class="progress" :style="{ width: progress }"></div>
                        </div>
                     </li>
                  </ul>
               </div>
               <div
                  v-if="
                     (showProgressBar && !el.advices && el._id === playingAdviceId) ||
                     (playPlayer && !el.advices && el._id === playingAdviceId)
                  "
                  class="progress-bar audioFile"
               >
                  <div class="progress" :style="{ width: progress }"></div>
               </div>
            </li>
         </ul>
         <Loader v-else-if="isLoading" />
         <div v-else class="isLoadingText"><Typography>There is no advices</Typography></div>
      </div>
      <!-- END LIST =====================================================-->
      <Modal v-model="isOpenModalAdvice" :modalContentClasses="['max-width-content']">
         <AddTipModal
            v-if="showAddTipModal"
            :selected="selected"
            @onCancel="isOpenModalAdvice = false"
            @onSave="addToListSave"
         />
      </Modal>
      <SWSnackBar ref="snackbar" />
   </div>
</template>

<script>
import Typography from '../../components/UI/Typography.vue';
import { SWCheckbox, SWSnackBar, SWButton, SWSearchinput } from 'sw-ui-kit';
import Teleport from '../../components/Teleport.vue';
import remoteOrigin from '../../store/remoteOrigin';
import Loader from '../../views/AdvicesApp/SettingMenu/Loader.vue';
import Modal from '../../components/UI/Modal.vue';
import AddTipModal from '../../views/AdvicesApp/AddTipModal.vue';
import Player from '../../views/AdvicesApp/Player.vue';
import * as Sentry from '@sentry/vue';
import { FORM_RENAME, FORM_DELETE } from '../Layouts/LayoutAplication.vue';
import lodashClonedeep from 'lodash.clonedeep';

export default {
   inject: ['openModal', 'openAdviceModal'],
   props: {},
   data() {
      return {
         isPressed: false,
         timeoutId: null,
         clientX: null,
         clientY: null,
         isLoading: false,
         audio: '',
         // showPlayerHabits: true,
         showPlayerAdvice: false,
         playPlayer: false,
         hoverEffect: true,
         showProgressBar: false,
         progress: null,
         playingAdviceId: null,
         // new stuff
         showPlayer: false,
         list: [],
         fullList: [],
         hidden: new Set([]),
         selected: new Set([]),
         editControls: false,
         mobilePlayAllHide: false, //!crutch for playAll button on mobiles to hide when selecting an item from list
         isMobile: false, 
         showHiddenMode: false,
         listKey: 0,
         isOpenModalAdvice: false,
         showAddTipModal: false,
         search: '',
         //end new stuff
         showInputAdviceMobail: false,
         mobileSearchActive: false,
         mobileEditMenuActive: false,
      };
   },
   components: {
      Typography,
      SWCheckbox,
      Loader,
      SWSnackBar,
      SWButton,
      Teleport,
      AddTipModal,
      Modal,
      Player,
      SWSearchinput,
   },
   async created() {
      //this.audio = new Audio(`${remoteOrigin}${this.audioSrc}`);
   },

   mounted() {
      
   },
   methods: {
      toggleEditcontrols() {
         this.editControls = !this.editControls;
      },

      selectItem(id) {
         if (this.editControls) {
            if (this.selected.has(id)) {
               this.selected.delete(id);
            } else {
               this.selected.add(id);
            }
         }
         this.listKey++;
         return;
      },

      hideSelected() {
         for (const el of this.selected) {
            this.hidden.add(el);
         }

         this.list = this.list.filter(el => {
            if (el.hasChilds) {
               if (el.childs) el.childs = el.childs.filter(el => !this.selected.has(el._id));
            }
            return !this.selected.has(el._id);
         });
      },

      hideUnSelected() {
         for (const el of this.list) {
            if (!this.selected.has(el._id) && !el.hasChilds) {
               this.hidden.add(el._id);
            }
         }

         this.list = this.list.filter(parent => {
            if (parent.hasChilds) {
               parent.childs = parent.childs.filter(el => {
                  if (!this.selected.has(el._id)) {
                     this.hidden.add(el._id);
                  } else {
                     this.selected.add(parent._id);
                  }
                  return this.selected.has(el._id);
               });
            }

            return this.selected.has(parent._id);
         });

      },

      allChildsHidden(el) {
         if (!el.childs?.length) return true;

         el.childs.forEach(el => {
            if (!this.hidden.has(el._id)) {
               return false;
            } else return true;
         });
      },

      renameList() {
         this.openModal(FORM_RENAME, {
            name: this.currentCategory.name,
            id: this.currentCategory.id,
            group: this.currentCategory.group,
         });
      },
      deleteList() {
         this.openModal(FORM_DELETE, { name: this.currentCategory.name, id: this.currentCategory.id, from: 'list' });
      },
      async saveChanges() {
         this.isLoading = true;
         await this.$http.post('resources/hide', {
            id: this.currentCategory.id,
            hidden: Array.from(this.hidden.values()),
         });

         let url = '/resources/list/';
         if (this.isListCustom) {
            url = '/resources/list/custom/';
         }

         const updatedList = await this.$http.get(`${url}${this.currentCategory.id}`);

         if (updatedList.data) {
            this.hidden.clear();
            this.selected.clear();
            this.showHiddenMode = false;
            this.list = lodashClonedeep(updatedList.data);
            this.fullList = lodashClonedeep(updatedList.data);
            this.mobileEditMenuActive = false;
            this.isLoading = false;
         } else {
            Sentry.captureMessage('No data from serer');
         }
      },
      cancelChanges() {
         this.showHiddenMode = false;
         this.selected.clear();
         this.hidden.clear();
         this.list = lodashClonedeep(this.fullList);
         this.mobileEditMenuActive = false;
      },
      showHidden() {
         this.showHiddenMode = true;
         this.selected.clear();
         const withChilds = lodashClonedeep(this.fullList).filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => this.hidden.has(el._id));
               return true;
            }
            return this.hidden.has(el._id);
         });
         this.list = withChilds;
         this.mobileEditMenuActive = false;
      },
      restoreHiddenSelected() {
         for (const el of this.selected) {
            this.hidden.delete(el);
         }
         this.selected.clear();
         const withChilds = this.list.filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => this.hidden.has(el._id));
               return true;
            }
            return this.hidden.has(el._id);
         });
         this.list = withChilds;
      },
      restoreHiddenunselected() {
         for (const el of this.list) {
            if (!this.selected.has(el._id)) {
               this.hidden.delete(el._id);
            }
         }

         const withChilds = this.list.filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => {
                  if (!this.selected.has(el._id)) {
                     this.hidden.delete(el._id);
                  }
                  return this.hidden.has(el._id);
               });
               return true;
            }
            return this.hidden.has(el._id);
         });

         this.selected.clear();

         this.list = withChilds;
      },
      backToList() {
         this.showHiddenMode = false;
         this.selected.clear();
         const withChilds = lodashClonedeep(this.fullList).filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => !this.hidden.has(el._id));
               return true;
            }
            return !this.hidden.has(el._id);
         });
         this.list = withChilds;
         this.mobileEditMenuActive = false;
      },

      async restoreOriginalList() {
         this.isLoading = true;
         await this.$http.post(`resources/restore/${this.currentCategory.id}`);

         const updatedList = await this.$http.get(`/resources/list/${this.currentCategory.id}`);

         if (updatedList.data) {
            this.list = lodashClonedeep(updatedList.data);
            this.fullList = lodashClonedeep(updatedList.data);
            this.hidden.clear();
            this.selected.clear();
            this.showHiddenMode = false;
            this.mobileEditMenuActive = false;
            this.isLoading = false;
         } else {
            Sentry.captureMessage('No data from serer');
         }
      },

      addToListSave() {
         this.isOpenModalAdvice = false;
         this.selected.clear();
      },

      saveToList() {
         this.isOpenModalAdvice = true;
         this.showAddTipModal = true;
      },

      onSerchBlur() {
         if (!this.search.length) {
            this.mobileSearchActive = false;
         }
      },

      closeEditControlsMobile() {
         this.editControls = false; //! crutch
         this.mobilePlayAllHide = false;
      },

      showInput() {
         if (!this.showInputAdviceMobail) {
            return (this.showInputAdviceMobail = true);
         } else {
            return this.showInputAdviceMobail;
         }
      },

      playAll() {
         this.editControls = false;
         this.showPlayer = !this.showPlayer;
      },

      handleTouchStart(event, itemIdPressed) {
         if (this.editControls || this.currentCategory.name === 'All') return;

         this.clientX = event.touches[0].clientX;
         this.clientY = event.touches[0].clientY;
         this.isPressed = true;
         this.timeoutId = setTimeout(() => {
            if (this.isPressed) {
               this.mobilePlayAllHide = true;
               this.editControls = true;
               this.$nextTick(() => {
                  this.selectItem(itemIdPressed);
               });
            }
            clearTimeout(this.timeoutId);
         }, 500);
      },

      handleTouchMove(event) {
         if (!this.isPressed) return;

         if (event.touches[0].clientX !== this.clientX || this.clientY !== event.touches[0].clientY) {
            clearTimeout(this.timeoutId);
            this.clientX = null;
            this.clientY = null;
            this.isPressed = false;
         }
      },

      showMessage(message) {
         this.$refs.snackbar.showSnackbar(message, 5000);
      },
      onPlay(media, id) {
         if (!media) return;
         this.playingAdviceId = id;

         if (this.audio) {
            this.audio.muted = true;
            this.audio.removeEventListener('ended', this.audioEnded);

            this.audio.removeEventListener('play', this.audioPlay);

            this.audio.removeEventListener('timeupdate', this.updateProgress);

            this.audio.removeEventListener('pause', this.audioPause);
            this.audio = null;
         }

         this.audio = new Audio(`${remoteOrigin}/storage/audio/${media}`);
         this.showProgressBar = true;

         this.audio.play();

         this.audio.addEventListener('ended', this.audioEnded);

         this.audio.addEventListener('play', this.audioPlay);

         this.audio.addEventListener('timeupdate', this.updateProgress);

         this.audio.addEventListener('pause', this.audioPause);
      },

      handlePlay(media, id) {
         if (this.playingAdviceId === id) {
            this.audio.play();
         } else {
            this.onPlay(media, id);
         }
         this.closePlayerAll();
      },

      highlightText(text) {
         if (!this.search) {
            return text;
         }

         const parts = text.split(new RegExp(`(${this.search})`, 'gi'));
         return parts
            .map(part => {
               if (part.toLowerCase() === this.search.toLowerCase()) {
                  return `<span class="highlighted">${part}</span>`;
               }
               return part;
            })
            .join('');
      },

      audioEnded() {
         this.playPlayer = false;
         this.progress = 0;
         this.showProgressBar = false;
      },

      audioPlay() {
         this.playPlayer = true;
         this.progress = 0;
         this.showProgressBar = true;
      },

      updateProgress() {
         this.progress = Math.ceil((this.audio.currentTime / this.audio.duration) * 100) + '%';
      },

      audioPause() {
         this.playPlayer = false;
      },

      closePlayerAll() {
         //this.$emit('closePlayerAll');
      },
   },

   computed: {
      isListCustom() {
         return this.$store.getters['app/getCurrentCategory'].group === 'list';
      },
      isListPredefined() {
         return this.$store.getters['app/getCurrentCategory'].predefined === true;
      },

      currentCategory() {
         return this.$store.getters['app/getCurrentCategory'];
      },
      /*  search() {
         return this.$store.getters['advice/getSearch'];
      }, */

      getVisibleItems() {
         return this.$store.getters['advice/getVisibleItems'];
      },
      closePlayer() {
         return this.$store.getters['app/getClosePlayer'];
      },
      settigRouteName() {
         return this.$route?.matched?.[1]?.name;
      },
   },
   watch: {
      async currentCategory() {
         this.isLoading = true;
         let url = '/resources/list/';
         if (this.isListCustom) {
            url = '/resources/list/custom/';
         }
         const response = await this.$http.get(`${url}${this.currentCategory.id}`);
         if (response.data) {
            this.list = lodashClonedeep(response.data);
            this.fullList = lodashClonedeep(response.data);
            this.hidden.clear();
            this.selected.clear();
            this.editControls = false;
            this.isLoading = false;
         } else {
            Sentry.captureMessage('No data from serer');
         }
      },
      closePlayer() {
         this.showPlayer = false;
      },

      playAdvice() {
         if (this.audio && this.playAllAdvices) {
            this.audio.muted = true;
            this.audio.removeEventListener('ended', this.audioEnded);

            this.audio.removeEventListener('play', this.audioPlay);

            this.audio.removeEventListener('timeupdate', this.updateProgress);

            this.audio.removeEventListener('pause', this.audioPause);
            this.audio = null;
            this.playingAdviceId = null;
         }
      },
      search() {
         if (!this.search) {
            if (this.showHiddenMode) {
               const withChilds = lodashClonedeep(this.fullList).filter(el => {
                  if (el.hasChilds) {
                     el.childs = el.childs.filter(el => this.hidden.has(el._id));
                     return true;
                  }
                  return this.hidden.has(el._id);
               });
               this.list = withChilds;
            } else {
               const withChilds = lodashClonedeep(this.fullList).filter(el => {
                  if (el.hasChilds) {
                     el.childs = el.childs.filter(el => !this.hidden.has(el._id));
                     return true;
                  }
                  return !this.hidden.has(el._id);
               });
               this.list = withChilds;
            }
         }

         const withChilds = lodashClonedeep(this.fullList).filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => !this.hidden.has(el._id));
               return true;
            }
            return !this.hidden.has(el._id);
         });

         let temp = withChilds;
         if (this.showHiddenMode) {
            const withChilds = lodashClonedeep(this.fullList).filter(el => {
               if (el.hasChilds) {
                  el.childs = el.childs.filter(el => this.hidden.has(el._id));
                  return true;
               }
               return this.hidden.has(el._id);
            });
            temp = withChilds;
         }

         const searchWithChilds = temp.filter(el => {
            if (el.hasChilds) {
               el.childs = el.childs.filter(el => el.text.includes(this.search));
               return true;
            }
            return el.text.includes(this.search);
         });

         this.list = searchWithChilds;
      },
   },
};
</script>

<style>
/* LIST STYLES */
.shackBar .snackbar {
   bottom: 10% !important;
   z-index: 10000 !important;
}
.isLoadingText {
   width: 200px;
   margin: 0 auto;
}
.container__habitsList {
   max-width: 902px;
   display: flex;
   flex-direction: column;
   overflow-y: auto;
   height: 72svh;
   width: 100%;
}
.progress-bar {
   width: 100%;
   background-color: #b9b9b9;
}
.marginBottom {
   margin-bottom: 10px;
}
.progress {
   height: 4px;
   width: 0;
   border-radius: 10px;
   background-color: #105ebc;
   transition: width 0s ease-in-out;
}
.loader {
   margin: 0 auto;
}

.backgroundPlayColor {
   background-color: var(--bg-install-element);
}
.hoverEffect:hover {
   background-color: var(--player-hover);
}

.content__habitsLList {
   display: flex;
   max-width: 818px;
   flex-direction: column;
}

.advice {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 30px 20px;
   border-bottom: 0.5px solid rgb(204, 204, 204);
}

.hoverEffect:hover {
   background-color: var(--player-hover);
}
.textHabis {
   margin-right: 20px;
}

.box_list {
   width: 100%;
   margin-bottom: 58px;
}
.img_btn {
   flex-shrink: 0;
}
.textHabis {
   max-width: 747px;
}
.playerImg {
   width: 19px;
   height: 21px;
}
.checkboxImg {
   right: -10px;
}
.advices_cat {
   margin-top: 20px;
   padding-left: 20px; 
   border-bottom: 0.5px solid rgb(204, 204, 204);
}
.advices_cat .advice {
   border-bottom: none;
   padding: 16px 20px 16px 30px;
}
.advices_cat ul {
   list-style-type: none;
}

.textAdvices {
   position: relative;
   padding-left: 18px;
}
.textAdvices::before {
   content: '';
   position: absolute;
   height: 4px;
   width: 4px;
   top: calc(1ch - 0.1em);
   left: 0;
   background-color: var(--typograohy-color);
   border-radius: 100%;
}
.advices_cat li::marker {
   color: var(--typograohy-color);
}
.textAdvices {
   max-width: 700px;
}

.highlighted {
   background-color: var(--highlight);
}

@media screen and (max-width: 600px) {
   .advice {
      padding: 30px 5px;
   }
   .textAdvices {
      max-width: 85%;
   }
   .advices_cat{
      padding-left: 5px;
   }
   .advices_cat ul {
      margin-left: 0;
   }
   .advices_cat .advice {
      padding-left: 15px;
   }
   .container__habitsList {
      margin-top: 30px;
      height: 82svh;
   }
   .advices_cat .advice {
      padding: 16px 0px 16px 14px;
   }
}
@media screen and (max-width: 420px) {
   .textHabis {
      max-width: 320px;
   }
   .shackBar .snackbar {
      left: 50% !important;
   }
}
/* END LIST STYLES */
/* LIST CONTROLS STYLES */
.container_headerList {
   display: flex;
   padding-top: 69px;
   padding-bottom: 40px;
}
.box_btn_playAll,
.box_btn_editList {
   max-width: 207px;
   width: 207px;
}
.box_btn_playAll {
   margin-right: 25px;
}
.box_btn_save {
   margin-left: 25px;
}
.box_btn_cancel {
   margin-left: 10px;
}
.img_playAll {
   width: 13px;
   height: 13px;
   margin-right: 12px;
}
.img_editList {
   width: 20px;
   height: 20px;
   margin-top: 2px;
   margin-right: 12px;
}
.btn_play,
.btn_edit {
   max-width: 207px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 42px;
}
.btn_play {
   color: #ffff !important;
}
.btn_edit {
   color: var(--shape) !important;
   border-color: var(--shape) !important;
}
.text_btnPlay,
.text_btnEdit {
   font-size: 14px;
   line-height: 18px;
   font-weight: 400;
   color: inherit;
}
@media screen and (max-width: 600px) {
   .container_headerList {
      padding: 0;      
      position: fixed;
      top: 85%;
      left: 0%;
      z-index: 99;
      width: 100%;
      display: flex;
      justify-content: center;
   }
   .box_btn_editList {
      display: none;
   }
   .box_btn_playAll {
      width: 100%;
      margin-right: 0px;
      min-width: 358px;
   }
   .btn_play {
      max-width: 358px;
   }
}
@media screen and (max-width: 600px) and (max-height: 1200px) {
   .container_headerList {
      top: 90%;
      left: 0%;
   }

}
@media screen and (max-width: 600px) and (max-height: 1000px) {
   .container_headerList {
      top: 85%;
      left: 0%;
   }

}

@media screen and (max-width: 414px) {
   /* .container_headerList {
      left: 32px;
   } */
}
@media screen and (max-width: 390px) {
 /*   .box_btn_playAll {
      transform: translateX(-10px);
   } */
   .container_headerList {
      top: 80%;

   } 
}

@media screen and (max-width: 375px) {

/*     .box_btn_playAll {
      
   }  */
}
@media screen and (max-width: 360px) {
/*    .box_btn_playAll {
      transform: translateX(-7px);
      transform: translateY(-7px);
   } */
}
/* END LIST CONTROLS STYLES */

/* EDIT CONTROLS STYLES */
.relative_box_editItem_two {
   position: relative;
}
.edit_img {
   width: 16px;
}
span {
   margin-left: 5px;
}
.content__adviceList {
   padding-bottom: 10px;
}
.container__menuEditList {
   font-size: 14px;
   max-width: 818px;
   padding: 48px 20px 0px 20px;

   line-height: 18px;
   color: var(--text-color-menulist);
}
.menuEditListCurrent {
   padding-top: 84px;
}
.btn_editItem1,
.btn_editItem2,
.btn_editItem3,
.btn_editItem4,
.btn_editItem5,
.btn_editItem6,
.btn_editItem7 {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 42.64px;
   padding: 8px;
}
.btn_editItem1:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem2:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem3:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem4:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem5:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem6:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem7:hover {
   background: var(--buttonHover);
   padding: 8px;
   border-radius: 8px;
}

.btn_editItem1:disabled,
.btn_editItem2:disabled,
.btn_editItem3:disabled,
.btn_editItem4:disabled,
.btn_editItem5:disabled,
.btn_editItem6:disabled,
.btn_editItem7:disabled {
   opacity: 0.5;
   cursor: not-allowed !important;
}

.editList_item4 {
   color: #b9c3d9;
}
.editList_item4 .btn_editItem4 {
   cursor: not-allowed;
}
.box_editItem_one,
.box_editItem_two {
   display: flex;

   /* padding-top: 5px; */
}
.box_editItem_one {
   padding-bottom: 14px;
}
.box_editItem_two {
   padding-top: 14px;
   padding-bottom: 14px;
}
.box_editItem_two {
   border-top: 0.7px solid var(--border-color-menulist);
}
.container__menuHiddenItem {
   position: absolute;
   top: -49px;
   font-size: 14px;
   transform: translateY(-2px);
   line-height: 18px;
   color: var(--text-color-menulist);
}
.btn_HiddenItem {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 8px;
   margin-right: 42.64px;
}
.btn_HiddenItem:hover {
   background: var(--buttonHover);

   border-radius: 10px;
}

.box_HiddenItem_one,
.box_HiddenItem_two {
   display: flex;
   /* 
    padding-top: 5px; */
}
.box_HiddenItem_one {
   padding-bottom: 14px;
}
.box_HiddenItem_two {
   padding-top: 14px;
}
.box_HiddenItem_two {
   border-top: 0.7px solid var(--border-color-menulist);
}
.btn_HiddenItem img {
   width: 16px;
   margin-right: 10px;
}

svg {
   stroke: #b9c3d9;
}
.activeHiddenItem {
   color: var(--text-color-menulist);
}
.activeHiddenItem svg {
   stroke: var(--text-color-menulist);
}
.activeHiddenItem .btn_editItem4 {
   cursor: pointer;
}
.menuEditHiden {
   display: none;
}
.menuEditHidenSave {
   display: none;
}
.btnMobilEditList {
   display: none;
}
.img_mobilClouse img {
   width: 32px;
}
.img_mobilClouse {
   transform: translate(10px, 10px);
}

.teleport__editMenu {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-left: 24px;
   font-weight: 400;
}
.teleport__editMenu button {
   padding: 15.5px 0;
   width: 100%;
   text-align: left;
}
.teleport__editMenu button:first-child {
   padding-top: 0;
}

.btnInTeleport_deleteList {
   color: red !important;
}
.save_cancel_container,
.save_cancel_container_mobile {
   display: flex;
   justify-content: space-between;
}
.save_cancel_container_mobile {
   margin-top: 10px;
   margin-bottom: 10px;
}
.save_cancel_container_mobile .btn_saveChanges,
.save_cancel_container_mobile .btn_cancelChanges {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 5px !important;
}
.save_cancel_container_mobile > .box_btn_save {
   margin-left: 0;
}
.btn_cancelChanges {
}
.is-desktop_show_selecter {
   display: flex;
   flex-direction: row;
}

@media screen and (max-width: 600px) {
   .save_cancel_container {
      display: none;
   }
   .menuEditListCurrent {
      padding-top: 0;
   }
   .relative_box_editItem_two {
      display: none;
   }
   .container__menuEditList {
      display: none;
   }

   .box_editItem_one,
   .box_editItem_two {
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
   }
   .box_HiddenItem_one,
   .box_HiddenItem_two {
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
   }
   .btn_editItem1,
   .btn_editItem2,
   .btn_editItem3,
   .btn_editItem4,
   .btn_editItem5,
   .btn_editItem6,
   .btn_editItem7 {
      margin-bottom: 15px;
   }
   .container__menuHiddenItem {
      display: none;
   }
   .menuEditHidenSave {
      display: block;
   }
   .box_editItem_two {
      border: none;
   }
   .editList_item5,
   .editList_item6,
   .is-desktop_show_selecter,
   .editList_item7 {
      display: none;
   }
   .btnEditMobHidenSave {
      position: fixed;
      padding: 13px 0px !important;
      bottom: -15px;
      left: 50%;
      transform: translate(-50%, -50%);
      /* background-color: var(--bg-install-element); */
   }
   .btnMobilEditList {
      display: block;
      position: fixed;
      z-index: 9994;
      left: 0;
      top: 1px;

      padding: 5px 5px 5px 20px;
      /* padding-left: 20px; */
      background-color: var(--bg-install-element);
      width: 100%;
   }

   .btnEditMobHiden {
      max-width: 140px !important;
      width: 140px !important;
      font-size: 14px !important;
      margin-right: 15px;
      padding: 13px !important;
   }
}

/* END EDIT CONTROLS STYLES */
</style>
